import { HelpTooltip } from '../../shared/components';
import styled from 'styled-components';
import { Radio } from 'antd';

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const HelpIcon = styled.div`
  margin-left: 4px;
`;

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  width: 100%;
`;

const StyledRadioButton = styled(Radio.Button)`
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  background-color: ${({ checked }) => (checked ? '#011741' : '#022973')};
  border: solid 2px #06abc1;

  &:hover {
    background-color: ${({ checked }) => (checked ? '#011741' : '#bababa')};
    color: 'white';
  }
`;

export function MinRegionCheck({ setValue, value }) {
  return (
    <div>
      <Title>
        <h4>Minimum Healthy Locations</h4>
        <HelpIcon>
          <HelpTooltip
            text="The minimum number of healthy locations reporting in before your site is considered online/offline.
                    Must be greater than or equal to the number of selected Locations."
          />
        </HelpIcon>
      </Title>

      <StyledRadioGroup
        value={value}
        onChange={(e) => setValue(e.target.value)}
        buttonStyle="solid"
        optionType="button">
        {[1, 2, 3].map((num) => (
          <StyledRadioButton
            key={num}
            value={num}
            checked={value === num}>
            {num}
          </StyledRadioButton>
        ))}
      </StyledRadioGroup>
    </div>
  );
}
