import { HelpTooltip, Icon } from '../../shared/components';
import { Select, Tag } from 'antd';
import styled from 'styled-components';

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const HelpIcon = styled.div`
  margin-left: 4px;
`;

const StyledTag = styled(Tag)`
  max-width: 200px;
  margin: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
`;

export function SelectRegionChecks({ setValue, value }) {
  const options = [
    { value: 'aws-us-east-1', label: 'US East (Virginia)' },
    { value: 'aws-us-east-2', label: 'US East (Ohio)' },
    { value: 'aws-us-west-1', label: 'US West (California)' },
    { value: 'aws-eu-west-2', label: 'EU (London)' },
  ];

  const handleChange = (data) => {
    setValue(data);
  };

  return (
    <div>
      <Title>
        <h3>Locations</h3>
        <HelpIcon>
          <HelpTooltip text="Different geographical locations to check from. Minimum 1" />
        </HelpIcon>
      </Title>
      <Select
        style={{ width: '100%', height: 'auto' }}
        suffixIcon={<Icon name="chevron-down" />}
        mode="multiple"
        placeholder="Please Select"
        defaultValue={value}
        onChange={handleChange}
        options={options}
        dropdownStyle={{ maxHeight: 200, overflowY: 'auto' }}
        filterOption={(input, option) =>
          (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
        }
        tagRender={({ label, closable, onClose }) => (
          <StyledTag
            closable={closable}
            onClose={onClose}>
            {label}
          </StyledTag>
        )}
      />
    </div>
  );
}
