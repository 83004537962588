import { useSelector } from 'react-redux';
import selectors from 'redux/selectors/selectors';
import { getUiDateShort, getUiRelativeTime } from 'shared/lib/dates';
import secondsToTime from 'shared/lib/secondsToTime';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { getDnsState } from '../../redux/actions/jobsActions';
import { Badge, Tooltip } from 'antd';
import getNotificationTooltip from '../../shared/lib/notificationsHelper';
import { getStateBadge, getStatusBadge } from '../../shared/components/Badges';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 1px #06abc1;
  border-radius: 2px;
  width: 100%;
  min-width: 240px;
  height: 100%;
  padding: 24px 12px;
`;

const Header = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 34px;
  font-size: 16px;
  font-weight: 700;
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;
`;
const Item = styled.div`
  display: flex;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: solid 1px #06abc1;
`;
const Key = styled.div`
  width: 50%;
`;
const Value = styled.div`
  width: 50%;
  text-transform: capitalize;
`;

export function SiteSidebarFeature() {
  const integrations = useSelector(selectors.integrations);
  const monitor = useSelector(selectors.httpMonitorDetails);
  const { expiry_date } = useSelector(selectors.dnsDetails);

  const [monitorState, setMonitorState] = useState(monitor);

  useEffect(() => {
    const interval = setInterval(() => {
      setMonitorState(monitor);
    }, 1000);

    return () => clearInterval(interval);
  }, [monitor]);

  const notificationGroupTitles = monitorState?.group_notifications?.map((group, index) => {
    const notifications = group.notifications?.map((notification) =>
      getNotificationTooltip(notification, integrations)
    );
    return (
      <span
        style={{
          marginRight: '5px',
        }}>
        <Tooltip
          key={`${index}-${group?.id}`}
          title={notifications}>
          <Badge
            count={group.friendly_name}
            color={'#a1a1a1'}
          />
        </Tooltip>
      </span>
    );
  });

  const hasDnsJob = !!monitorState?.dns_job_id;
  useEffect(() => {
    if (hasDnsJob) {
      getDnsState(monitorState.dns_job_id);
    }
  }, [hasDnsJob, monitorState]);
  return (
    <Container>
      <Header>Details</Header>
      <Body>
        <Item>
          <Key>Status</Key>
          <Value>{getStatusBadge(monitorState.current_status)}</Value>
        </Item>
        <Item>
          <Key>State</Key>
          <Value>{getStateBadge(monitorState.state)}</Value>
        </Item>
        <Item>
          <Key>Run Interval</Key>
          <Value>
            {secondsToTime({ seconds: monitorState.run_interval_seconds, withoutSeconds: true })}
          </Value>
        </Item>
        <Item>
          <Key>Timeout</Key>
          <Value>{monitorState.timeout_ms / 1000} seconds</Value>
        </Item>
        <Item>
          <Key>Last Checked</Key>
          <Value>
            {monitorState?.last_run_at ? getUiRelativeTime(monitorState?.last_run_at) : 'Never'}
          </Value>
        </Item>
        <Item>
          <Key>Last Change</Key>
          <Value>
            {monitorState.last_status_change_at
              ? getUiDateShort(monitorState.last_status_change_at)
              : 'Never'}
          </Value>
        </Item>
        <Item>
          <Key>Date Created</Key>
          <Value>{getUiDateShort(monitorState.created_at)}</Value>
        </Item>
        {hasDnsJob && (
          <Item>
            <Key>Domain Expiration</Key>
            <Value>{getUiDateShort(expiry_date)}</Value>
          </Item>
        )}

        <Item>
          <Key>Notification Group</Key>
          <Value>{notificationGroupTitles}</Value>
        </Item>
      </Body>
    </Container>
  );
}
