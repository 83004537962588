import {useSelector} from 'react-redux';
import selectors from 'redux/selectors/selectors';
import {Select, Tag} from 'antd';
import {HelpTooltip, Icon} from 'shared/components';
import styled from 'styled-components';

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const HelpIcon = styled.div`
  margin-left: 4px;
`;

const StyledTag = styled(Tag)`
  max-width: 200px;
  margin: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
`;

export function SelectNotificationGroup({setValue, value}) {
    const availableGroups = useSelector(selectors.notificationGroups);

    const options = availableGroups.map((group) => ({
        value: group.id,
        label: group.friendly_name,
    }));

    return (
        <div>
            <Title>
                <h3>Notifications</h3>
                <HelpIcon>
                    <HelpTooltip text="A list of who to notify when a site goes up or down."/>
                </HelpIcon>
            </Title>
            <Select
                suffixIcon={<Icon name="chevron-down"/>}
                style={{width: '100%', height: 'auto'}}
                mode="multiple"
                placeholder="Please Select"
                defaultValue={value.map((group) => group.id)}
                onChange={(data) => {
                    setValue(data.map((id) => availableGroups.find((group) => group.id === id)));
                }}
                options={options}
                filterOption={(input, option) =>
                    (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                tagRender={({label, closable, onClose}) => (
                    <StyledTag
                        key={label}
                        closable={closable}
                        onClose={onClose}>
                        {label}
                    </StyledTag>
                )}
            >
                {availableGroups.map((group) => (
                    <Select.Option
                        key={group.id}
                        value={group.id}>
                        {group.friendly_name}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
}
