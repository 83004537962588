import {Head, Title} from 'shared/styled/styled';
import {Button, Table, Tooltip} from 'antd';
import {useEffect, useState} from 'react';
import selectors from '../../redux/selectors/selectors';
import {
    createAccountApiKey,
    deleteAccountApiKey,
    getAccountApiKeys
} from '../../redux/actions/eCommerceActions';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {getUiDateShort} from '../../shared/lib/dates';
import {Icon} from '../../shared/components';
import ConfirmModal from '../../shared/components/ConfirmModal';
import {Footer} from './styled';
import useBoundForm from '../../shared/hooks/useBoundForm';
import BoundFormGroup from '../../shared/components/boundForm/BoundFormGroup';

const ApiKeysTable = styled.div`
  padding: 0 34px 12px 34px;
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
`;

const ApiKeyContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 200px; /* Set fixed max-width */
  align-items: center; /* Ensure items are centered vertically */
  gap: 8px; /* Add some space between the preview and the icon */
`;

const ApiKeyPreview = styled.div`
  flex-shrink: 10; /* Allow the preview to shrink if necessary */
  background-color: #011741;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 8px;
  font-family: monospace;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  font-size: 12px;
  max-width: 400px; /* Ensure the preview does not exceed the container's max width */

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export function BlockApiKeys() {
    const apiKeys = useSelector(selectors.accountApiKeys);
    const form = useBoundForm({});
    const [loading, setLoading] = useState(false);
    const [copied, setCopied] = useState(false);
    const [showApiKey, setShowApiKey] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [deleteApiKey, setDeleteApiKey] = useState(null);

    const fieldsAddApiKey = [
        [{id: 'friendly_name', label: 'Name', format: 'string', placeholder: 'Api Key Name'}]
    ];

    useEffect(() => {
        setLoading(true);
        getAccountApiKeys().then(() => {
            setLoading(false);
        });
    }, []);

    const handleCopy = (apiKey) => {
        navigator.clipboard.writeText(apiKey).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        });
    };

    const handleDelete = () => {
        deleteAccountApiKey(deleteApiKey).then(() => {
            setShowDeleteModal(false);
            getAccountApiKeys();
        });
    };

    const handleAdd = () => {
        createAccountApiKey(form.state.friendly_name).then(() => {
            setShowAddModal(false);
        });
    };

    const columns = [
        {
            title: 'Name',
            render: (record) => {
                return <div>{record.friendly_name}</div>;
            }
        },
        {
            title: 'API Key',
            render: (record) => {
                return (
                    <ApiKeyContainer>
                        <ApiKeyPreview>{showApiKey ? record.key : '*'.repeat(record.key.length)}</ApiKeyPreview>
                        <Tooltip title={!showApiKey ? 'View' : 'Hide'}>
                            <Icon
                                style={{cursor: 'pointer'}}
                                size={25}
                                name={!showApiKey ? 'eye' : 'eye-crossed'}
                                onClick={() => {
                                    setShowApiKey(!showApiKey);
                                }}
                            />
                        </Tooltip>
                        <Tooltip title={copied ? 'Copied!' : 'Copy'}>
                            <Icon
                                style={{cursor: 'pointer'}}
                                size={25}
                                name={'copy'}
                                onClick={() => handleCopy(record.key)}
                            />
                        </Tooltip>
                    </ApiKeyContainer>
                );
            }
        },
        {
            title: 'Created',
            render: (record) => {
                return <div>{getUiDateShort(record.created_at)}</div>;
            }
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <div
                        style={{
                            justifyContent: 'center',
                            display: 'flex',
                            alignContent: 'center'
                        }}>
                        <Tooltip title="Delete">
                            <Icon
                                style={{cursor: 'pointer'}}
                                size={25}
                                name={'trash'}
                                onClick={() => {
                                    setDeleteApiKey(record.id);
                                    setShowDeleteModal(true);
                                }}
                            />
                        </Tooltip>
                    </div>
                );
            }
        }
    ];

    return (
        <div>
            <Head>
                <Title>API Keys</Title>
            </Head>
            <ApiKeysTable>
                <Table
                    loading={loading}
                    pagination={{
                        hideOnSinglePage: true,
                        defaultPageSize: 10,
                        showSizeChanger: false,
                        position: ['bottomRight']
                    }}
                    dataSource={apiKeys}
                    columns={columns}
                    rowKey={(record) => record.id}
                    scroll={{x: '100%'}}
                />
            </ApiKeysTable>
            <ConfirmModal
                title={'Delete API Key'}
                show={showDeleteModal}
                onCancel={() => setShowDeleteModal(false)}
                onOk={handleDelete}
                okText="Delete"
                text={
                    <>
                        <div style={{textAlign: 'center'}}>Are you sure you want to delete this API key?</div>
                        <br/>
                        <div style={{textAlign: 'center'}}>Note: This action cannot be undone</div>
                    </>
                }
            />
            <ConfirmModal
                title={'Add API Key'}
                show={showAddModal}
                onCancel={() => setShowAddModal(false)}
                onOk={handleAdd}
                okText="Add"
                text={
                    <div style={{width: '100%'}}>
                        <BoundFormGroup
                            formId={form.id}
                            fields={fieldsAddApiKey}
                        />
                    </div>
                }
            />
            <Footer>
                <Button
                    className="primary"
                    onClick={() => setShowAddModal(true)}>
                    Add API Key
                </Button>
            </Footer>
        </div>
    );
}
