import { Button, Table, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import selectors from 'redux/selectors/selectors';
import { CheckBarGraphFeature } from './check-bar-graph';
import secondsToTime from 'shared/lib/secondsToTime';
import { useState } from 'react';
import styled from 'styled-components';
import getNotificationTooltip from '../../shared/lib/notificationsHelper';
import { getUiRelativeTime } from '../../shared/lib/dates';
import { getStateBadge, getStatusBadge } from '../../shared/components/Badges';
import { Badge } from 'antd';
import { listMoreMonitors } from '../../redux/actions/monitorsActions';

const ShowMore = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

export function SiteTableFeature() {
  const navigate = useNavigate();
  const integrations = useSelector(selectors.integrations);
  const allMonitors = useSelector(selectors.combinedMonitorsSorted) || [];
  const httpMonitors = useSelector(selectors.httpMonitors) || [];
  const cronMonitors = useSelector(selectors.cronMonitors) || [];
  const filters = useSelector(selectors.monitorsFilters) || {};
  const totals = useSelector(selectors.monitorsTotals) || [];
  const [isLoading, setIsLoading] = useState(false);

  const isCron = (monitor) => {
    return monitor.method === undefined;
  };

  const hasMoreSites = () => {
    if (filters.searchText.length) {
      return false;
    }
    return allMonitors.length < totals.total;
  };

  const showMore = () => {
    setIsLoading(true);
    listMoreMonitors({
      limit: 10,
      offset: Math.max(cronMonitors.length, httpMonitors.length),
      searchText: filters.searchText,
      statusFilter: filters.searchStatus,
      stateFilter: filters.searchState,
    }).then(() => {
      setIsLoading(false);
    });
  };

  const getNotificationsTooltip = (notifications) => {
    notifications = notifications || [];

    notifications.sort((a, b) => {
      if (a.friendly_name < b.friendly_name) {
        return -1;
      }
      if (a.friendly_name > b.friendly_name) {
        return 1;
      }
      return 0;
    });

    const notification_groups = notifications?.map((item, index) => {
      const notifications = item.notifications?.map((notification) =>
        getNotificationTooltip(notification, integrations)
      );
      return (
        <span
          style={{
            marginRight: '5px',
          }}>
          <Tooltip
            key={index}
            title={notifications}>
            <Badge
              count={item.friendly_name}
              color={'#a1a1a1'}
            />
          </Tooltip>
        </span>
      );
    });
    return <span>{notification_groups}</span>;
  };

  const columns = [
    {
      title: 'Name',
      key: 'friendly_name',
      width: '30%',
      render: (record) => {
        return (
          <strong>
            <div>{record.friendly_name}</div>
            {isCron(record) && (
              <Tooltip title={record.id}>
                <Badge
                  count={'Cron'}
                  color={'#a1a1a1'}
                />
              </Tooltip>
            )}
            <div style={{ fontSize: '12px' }}>{record.uri}</div>
          </strong>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'current_status',
      key: 'current_status',
      render: (text) => getStatusBadge(text),
    },
    {
      title: 'Duration',
      dataIndex: 'last_status_change_at',
      key: 'duration',
      render: (record) => {
        return <span>{record ? getUiRelativeTime(record) : 'Pending'}</span>;
      },
      onCell: (record) => ({
        style: {
          minWidth: '150px',
        },
      }),
      // width: '200px',
    },
    {
      title: 'Interval',
      key: 'run_interval_seconds',
      render: (record) => {
        return (
          <span>
            {secondsToTime({
              seconds: record.run_interval_seconds || record.check_interval_seconds,
              withoutSeconds: true,
            })}
          </span>
        );
      },
      onCell: (record) => ({
        style: {
          minWidth: '150px',
        },
      }),
      // width: '200px',
    },
    // {
    //   title: 'DNS / TLS',
    //   key: 'dns_tls',
    //   render: (record) => {
    //
    //     let onColor = '#26AF74';
    //     let offColor = '#E8403E';
    //
    //     return (<div
    //       style={{
    //         display: 'flex'
    //       }}
    //     >
    //       <Tooltip
    //         title={record.monitor_dns ? 'DNS Monitoring is enabled' : 'DNS Monitoring is disabled'}
    //       >
    //         <Icon
    //           name={record.monitor_dns ? 'toggle-on-fill' : 'toggle-off-fill'}
    //           size={35}
    //           color={record.monitor_dns ? onColor : offColor}
    //         />
    //       </Tooltip>
    //       <span style={{ alignContent: 'center' }}>
    //         /
    //       </span>
    //       <Tooltip
    //         title={record.monitor_tls ? 'TLS Monitoring is enabled' : 'TLS Monitoring is disabled'}
    //       >
    //         <Icon
    //           name={record.monitor_tls ? 'toggle-on-fill' : 'toggle-off-fill'}
    //           size={35}
    //           color={record.monitor_tls ? onColor : offColor}
    //         />
    //       </Tooltip>
    //     </div>);
    //   }
    // },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      render: (text) => getStateBadge(text),
    },
    {
      title: 'Notifications',
      dataIndex: 'group_notifications',
      key: 'group_notifications',
      render: getNotificationsTooltip,
    },
    {
      title: 'Recent',
      dataIndex: '',
      key: 'graph',
      width: '30%',
      render: (record) => {
        return (
          <CheckBarGraphFeature
            jobId={record.id}
            length={20}
          />
        );
      },
      onCell: (record) => ({
        style: {
          minWidth: '300px',
        },
      }),
    },
  ];

  return (
    <>
      <Table
        pagination={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: (_event) => {
              if (isCron(record)) {
                navigate(`/cron/${record.id}`);
              } else {
                navigate(`/site/${record.id}`);
              }
            },
          };
        }}
        rowClassName="clickable-row"
        rowKey={(record) => record.id}
        dataSource={allMonitors}
        columns={columns}
        scroll={{ x: '100%' }}
      />

      <ShowMore>
        <Button
          loading={isLoading}
          disabled={!hasMoreSites()}
          onClick={showMore}>
          Show More
        </Button>
      </ShowMore>
    </>
  );
}
