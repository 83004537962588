import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import Modal from 'shared/components/Modal';
import BoundFormGroup from 'shared/components/boundForm/BoundFormGroup';
import useBoundForm from 'shared/hooks/useBoundForm';
import { postMonitor, putMonitor } from 'redux/actions/jobsActions';
import { useSelector } from 'react-redux';
import selectors from 'redux/selectors/selectors';
import ModalBody from 'shared/components/ModalBody';
import ModalFooter from 'shared/components/ModalFooter';
import { SelectNotificationGroup } from 'features/notifications/select-notification-group';
import { mapBackInterval, marksInterval, marksTimeout, valuesInterval } from './mappings';
import { MonitorModalTitle } from './monitor-modal-title';
import { SelectRegionChecks } from '../regions/select-region-checks';
import { MinRegionCheck } from '../regions/min-region-check';

const getInitialState = (monitor = {}) => ({
  friendly_name: monitor?.friendly_name || '',
  uri: monitor?.uri || '',
  method: monitor?.method || 'GET',
  timeout_ms: parseFloat(monitor?.timeout_ms || 15000) / 1000,
  run_interval_seconds: mapBackInterval(parseFloat(monitor?.run_interval_seconds || 300) / 60),
  keyword_search: monitor?.keyword_search || '',
  monitor_tls: monitor?.monitor_tls ?? true,
  monitor_dns: monitor?.monitor_dns ?? true,
});

const httpMethodOptions = [
  { value: 'GET', label: 'GET' },
  { value: 'POST', label: 'POST' },
  { value: 'PUT', label: 'PUT' },
  { value: 'PATCH', label: 'PATCH' },
  { value: 'DELETE', label: 'DELETE' },
  { value: 'HEAD', label: 'HEAD' },
];

const AdvancedLink = styled.div`
  width: 100%;
  text-align: center;
  margin: 12px 0;
`;

const SiteModal = ({ show, adding, jobId }) => {
  const navigate = useNavigate();
  const [showAdvanced, setShowAdvanced] = useState(false);
  const monitor = useSelector(selectors.httpMonitorDetails);
  const [notifications, setNotifications] = useState(monitor?.group_notifications ?? []);
  const [checkRegions, setCheckRegions] = useState(monitor?.check_regions ?? ['aws-us-east-1']);
  const [minRegionCheck, setMinRegionCheck] = useState(monitor?.min_healthy_regions ?? 1);
  const form = useBoundForm({ initialState: getInitialState(monitor) });

  const fields = [
    [{ id: 'friendly_name', label: 'Name', placeholder: 'Google', required: true }], //
    [{ id: 'uri', label: 'URL or IP', placeholder: 'https://google.com', required: true }],
    [
      {
        id: 'run_interval_seconds',
        label: 'Check Interval Minutes',
        placeholder: 'In minutes',
        type: 'slider',
        marks: marksInterval,
        min: 1,
        max: 12,
        step: 1,
        tooltip: { open: false },
        helpText: 'How often to check your site.',
      },
    ],
  ];

  const fieldsAdvanced = [
    [
      {
        id: 'keyword_search',
        label: 'Keyword',
        placeholder: 'Keyword',
        helpText:
          "If set, the site will be monitored for the existence of this keyword. If the keyword is not found even if the site is responding, it'll be considered “Down”",
      },
    ], //
    [
      {
        id: 'timeout_ms',
        label: 'Timeout Seconds',
        type: 'slider',
        marks: marksTimeout,
        min: 1,
        max: 60,
        step: 1,
        helpText: 'How long to wait for a site to respond before considering it “Down”',
      },
    ],
    [{ id: 'method', label: 'Method', placeholder: 'HTTP Method', options: httpMethodOptions }],
    [
      {
        id: 'monitor_tls',
        label: 'Monitor TLS/SSL',
        type: 'checkbox',
        helpText: "An option to monitor the domain's TLS/SSL Certificate",
      },
    ],
    [
      {
        id: 'monitor_dns',
        label: 'Monitor DNS',
        type: 'checkbox',
        helpText: "An option to monitor the domain registry's expiry date",
      },
    ],
  ];

  const handleHide = () => {
    if (!adding) {
      navigate(`/site/${jobId}`);
      return;
    }
    navigate('/dashboard');
  };

  const toggleAdvanced = () => {
    setShowAdvanced(!showAdvanced);
  };

  const handleSaveClick = () => {
    const data = {
      ...form.state,
      run_interval_seconds: valuesInterval[form.state.run_interval_seconds],
      group_notification_ids: notifications.map((notification) => notification.id),
      check_regions: checkRegions,
      min_healthy_regions: minRegionCheck,
    };
    form.validate();
    if (form.isValid) {
      if (jobId) {
        putMonitor(jobId, data).then(() => {
          form.reset(getInitialState());
        });
      } else {
        postMonitor(data).then(() => {
          form.reset(getInitialState());
        });
      }
      handleHide();
    }
  };

  useEffect(() => {
    if (adding) {
      form.reset(getInitialState());
    } else {
      form.reset(getInitialState(monitor));
    }
    setNotifications(monitor?.group_notifications ?? []);
  }, [monitor, adding]);

  return (
    <Modal
      show={show}
      className={'site-modal'}
      onHide={handleHide}
      title={MonitorModalTitle({ adding })}>
      <ModalBody>
        <BoundFormGroup
          formId={form.id}
          fields={fields}
        />
        <SelectNotificationGroup
          value={notifications}
          setValue={setNotifications}
        />

        <AdvancedLink>
          <Button
            size="small"
            onClick={toggleAdvanced}>
            Advanced
          </Button>
        </AdvancedLink>

        {showAdvanced && (
          <>
            <BoundFormGroup
              formId={form.id}
              fields={fieldsAdvanced}
            />
            <SelectRegionChecks
              value={checkRegions}
              setValue={setCheckRegions}
            />
            <MinRegionCheck
              value={minRegionCheck}
              setValue={setMinRegionCheck}
            />
          </>
        )}
      </ModalBody>

      <ModalFooter>
        <button
          className={'save primary'}
          onClick={handleSaveClick}>
          Submit
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default SiteModal;
